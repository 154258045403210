import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
// import gql from "graphql-tag"
import Layout from "../dsm/layout"
import Inner from "../dsm/layout/Inner"
import PageHeader from "../layouts/PageHeader"
// import { useQuery } from "@apollo/react-hooks"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////

const NotFoundPage = () => {
  // const { loading, error, data } = useQuery(QUERY)

  return (
    <Layout>
      <PageHeader title="Page Not Found" crumbs={[]} />
      <Wrapper>
        <Inner>
          <p>We could not find the page you we're looking for.</p>
          <p>
            Please use the navigation to find your way, or head back to our{" "}
            <Link to="/">Homepage</Link>
            ...
          </p>
        </Inner>
      </Wrapper>
    </Layout>
  )
}

export default NotFoundPage

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Wrapper = styled.section`
  padding: 60px 0;
`

// const QUERY = gql`
//   {
//     allSettings {
//       readingSettingsPostsPerPage
//     }
//   }
// `
